<template>
  <header class="d-flex justify-content-between p-5">
    <div>
      <!-- Logo à gauche -->
      <img class="logo" src="./assets/izzy-solution_logo.svg" alt="Logo" />
    </div>
    <div class="d-flex">
      <!-- Bouton "email" à droite -->
      <div class="px-5">
        <a class="btn-tel text-dark" href="tel:0032478124140"
          >Tel. +32 478 12 41 40</a
        >
      </div>
      <div>
        <a href="mailto:info@izzysolution-car.be" class="btn btn-lg btn-primary"
          >Email</a
        >
      </div>
    </div>
  </header>
  <router-view />
</template>
