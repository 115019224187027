<template>
  <div>
    <main class="container py-5">
      <div class="row pt-5">
        <div class="col-md-12" style="text-align: left">
          <h1 style="text-decoration: line-through">LEASING REFUSÉ&thinsp;?</h1>
          <p class="h1" style="font-weight: normal">
            La banque estime votre société trop jeune, vous n’avez pas assez de
            garanties ou vous êtes fiché bancaire, nous avons la solution pour
            vous.
          </p>
          <div class="d-flex py-5">
            <!-- Bouton "email" à droite -->
            <div>
              <a
                href="mailto:info@izzysolution-car.be"
                class="btn btn-lg btn-primary"
                >Email</a
              >
            </div>
            <div class="px-5">
              <a class="btn-tel text-dark" href="tel:0032478124140"
                >Tel. +32 478 12 41 40</a
              >
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="bg-primary pb-5 mt-200">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <img
            class="mt-200-neg"
            src="../assets/izzy-solution_photo.jpg"
            style="border-radius: 15px; width: 100%"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-8" style="text-align: left">
          <p>
            <b
              >Notre société s'auto-finance et vous met à disposition un
              véhicule pour votre société.</b
            >
            Que ce soit véhicule neuf, de direction ou d’occasion, nous nous
            occupons de le trouver pour vous. Là ou la banque s'appuie sur des
            données financières exigeantes, nous vous facilitons les choses avec
            des critères plus souples. Nous fonctionnons avec 20% d'apport, les
            mensualités classique d'un leasing ainsi qu'une valeur résiduelle.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <p
            class="h1 py-4"
            style="
              text-align: left;
              border-top: 1px solid #000;
              border-bottom: 1px solid #000;
            "
          >
            Pas d'engagement bancaire, le taux d’acceptation est de 96%.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <img
          class="my-5"
          style="max-width: 800px; width: 90%; margin: auto"
          src="../assets/izzy-solution_logos-voitures.svg"
        />
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align: left">
          <p class="h1 py-4">À propos de nous</p>
        </div>
      </div>
      <div class="row my-5 a-propos-row">
        <div class="col-md-6" style="text-align: left">
          <p class="mb-5">
            Izzy Solution propose des solutions de financement personnalisé pour
            des véhicules neufs, de directions ou d'occasions. Depuis 2012, nous
            vous offrons des options de crédit-bail excellentes, ainsi qu'une
            connaissance étendue de l'industrie des camions.
          </p>
        </div>
        <div class="col-md-6" style="text-align: left">
          <p class="mb-5">
            <b>Mission de Izzy Solution</b><br />
            Izzy Solution garantit à chaque entrepreneur un véhicule d'occasion
            à crédit : fiable et en état de marche.
          </p>
          <p>
            <b>Vision de Izzy Solution</b><br />
            Izzy Solution stimule de manière innovante l'esprit d'entreprise
            dans le secteur de la mobilité.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-5 mt-200">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <p class="h1" style="font-weight: normal">
            Un leasing traditionnel n'est qu'une solution financière. Nous
            pensons que la clé du succès est une bonne coopération. C'est
            pourquoi nous vous en offrons davantage dans le leasing IZZY
            SOLUTION.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align: left">
          <picture style="width: 100%">
            <!-- Source d'image pour les écrans de taille moyenne -->
            <source
              style="width: 100%"
              media="(max-width: 920px)"
              srcset="../assets/izzy-solution_tableau_mobile.svg"
            />
            <!-- Source d'image pour les écrans de grande taille -->
            <source
              style="width: 100%"
              srcset="../assets/izzy-solution_tableau.svg"
            />

            <!-- Balise <img> pour une image de secours ou pour les navigateurs qui ne prennent pas en charge <picture> -->
            <img
              style="width: 100%"
              src="../assets/izzy-solution_tableau.svg"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-5 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <p class="h1" style="font-weight: normal">
            <b>Demandez votre devis</b> et démarrez dès aujourd'hui, nous vous
            fournirons un devis dans les 24h&thinsp;!
          </p>
          <div class="d-flex py-5">
            <!-- Bouton "email" à droite -->
            <div>
              <a
                href="mailto:info@izzysolution-car.be"
                class="btn btn-lg btn-primary"
                >Email</a
              >
            </div>
            <div class="px-5">
              <a class="btn-tel text-dark" href="tel:0032478124140"
                >Tel. +32 478 12 41 40</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-5 mt-5" style="text-align: left; font-size: 16px">
    <div class="row">
      <div class="col-md-6">© 2023 Izzy Solution</div>
      <div class="col-md-6" style="text-align: right">
        <a href="https://izzysolution-immo.be" class="text-dark">
          Nous proposons aussi des solutions de financement immobilier
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style scoped>
/* Ajoute tes styles personnalisés ici */
</style>
